import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// plugins

import { ClipboardModule } from 'ngx-clipboard';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxIbanModule } from 'ngx-iban';
import { QuicklinkModule } from 'ngx-quicklink';
import { TranslateModule } from '@ngx-translate/core';
import { GooglePayButtonModule } from '@google-pay/button-angular';

// components
import { HeaderComponent } from './components/header/v1/header.component';
import { LanguageChangeComponent } from './components/language-change/language-change.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { NotSuitableToIframeComponent } from './components/not-suitable-to-iframe/not-suitable-to-iframe.component';
import { AlertComponent } from './components/alert/alert.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

// directives
import { LoadingDirective } from '@shared/directives/loading.directive';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
// pipes
import { ReplaceLineBreaks } from './pipes/replace-line-breaks.pipe';

// ngx-venus
import { NgxVenusDirectivesModule, NgxVenusPipesModule } from 'ngx-venus';
import { HeaderV2Component } from './components/header/v2/header.component';
const maskConfig = {
  validation: false,
};

@NgModule({
  imports: [
    // core
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // ngx-venus
    NgxVenusDirectivesModule,
    NgxVenusPipesModule,
    // plugins

    ClipboardModule,
    TooltipModule.forRoot(),
    NgxPermissionsModule.forChild(),
    NgxIbanModule,
    GooglePayButtonModule,

    QuicklinkModule,
    TranslateModule.forChild(),
    CreditCardDirectivesModule,
    NgxMaskDirective,
  ],
  declarations: [
    // directives
    LoadingDirective,
    // pipes
    ReplaceLineBreaks,
    // components
    HeaderComponent,
    HeaderV2Component,
    LanguageChangeComponent,
    NotSuitableToIframeComponent,
    NotfoundComponent,
    AlertComponent,
    UnauthorizedComponent,
  ],
  exports: [
    // directives
    LoadingDirective,
    // pipes
    ReplaceLineBreaks,
    // components
    HeaderComponent,
    HeaderV2Component,
    LanguageChangeComponent,
    NotfoundComponent,
    NotSuitableToIframeComponent,
    AlertComponent,
    UnauthorizedComponent,
    // ngx-venus
    NgxVenusDirectivesModule,
    NgxVenusPipesModule,
    // core
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // modules
    ClipboardModule,
    TooltipModule,
    NgxPermissionsModule,
    NgxIbanModule,
    GooglePayButtonModule,
    QuicklinkModule,
    TranslateModule,
    CreditCardDirectivesModule,
    NgxMaskDirective,
  ],
  providers: [
    provideNgxMask(maskConfig), // Providing NgxMask with config
  ],
})
export class SharedModule {}
